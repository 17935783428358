import Glide, { Autoplay, Breakpoints, Controls, Keyboard, Sizes, Swipe } from '@glidejs/glide/dist/glide.modular.esm'

export default () => ({
    userData: {
        contact: {
          name: '',
          institution: '',
          email: ''
        },
        researchGoals: {},
        dataSource: {
            technology: '',
            otherOmics: '',
            method: '',
            otherMethod: '',
            singleBulk: 'single cell technology',
            conditions: 3,
            samples: 10,
            cells: 100000,
            comment: ''
        },
    },
    recommendationsHtml: {},
    activeGoalsCounter: 0,
    formIsInProgress: false,
    progressStep: 1,
    overlayIsOpen: false,
    glide: null,

    get hash() {
      return `#lead-${this.progressStep}`
    },

    get nextLabel() {
      if (this.progressStep === 1) {
        return 'Continue to data source'
      }

      if(this.progressStep === 2) {
        return 'Continue to contact details'
      }

      return 'Continue'
    },

    get nextDisabled() {
      return !this.formIsValid(this.progressStep)
    },

    // methods
    init() {
      this.glide = new Glide('.glide', {
        gap: 40,
        perView: 7,
        type: 'carousel',
        breakpoints: {
          767: { gap: 20, perView: 2 },
          1200: { gap: 20, perView: 3 },
          1600: { gap: 30, perView: 4 },
          2000: { gap: 30, perView: 5 },
          2400: { gap: 30, perView: 6 },
          2800: { gap: 40, perView: 7 },
        }
      }).mount({ Controls, Autoplay, Keyboard, Breakpoints, Swipe })
    },

    openOverlay() {
      this.overlayIsOpen = true
      this.formIsInProgress = true
      this.progressStep = 1
      window.history.pushState({ progressStep: this.progressStep }, '')
    },

    closeOverlay() {
      // Rest form before closing, if it has been finished before
      if (this.progressStep === 4) {
        this.reset()
      }

      this.overlayIsOpen = false
      this.progressStep = 1

      window.location.hash = ''
    },

    previous() {
      if(this.progressStep === 1) {
        this.closeOverlay()
      } else {
        this.progressStep--
      }
      this.$refs.leadgeneratorScrollable.scroll({ top: 0, behavior: "auto" })
      window.history.pushState({ progressStep: this.progressStep }, '')
    },

    next() {
      this.progressStep++
      this.formIsInProgress = true
      this.$refs.leadgeneratorScrollable.scroll({ top: 0, behavior: "auto" })
      window.history.pushState({ progressStep: this.progressStep }, '')
    },

    async submit() {
      const userData = {
        ...this.userData.contact,
        ...this.userData.dataSource,
        researchGoals: Object.keys(this.userData.researchGoals),
      }

      if (userData.singleBulk !== 'single cell technology') {
        userData.cells = null
      }

      const response = await window.fetch('/_/create_lead', {
        method: 'post',
        body: JSON.stringify(userData),
      })

      const body = await response.json()

      if (body.status === 'error') {
        alert(body.error)
        return
      }

      this.recommendationsHtml = body.recommendations
      this.next()
    },

    toggleResearchGoal(item) {
      if(this.userData.researchGoals[item]) {
        this.activeGoalsCounter--
      } else {
        this.activeGoalsCounter++
      }

      this.userData.researchGoals[item] = !this.userData.researchGoals[item]
    },

    switchDataSource(technology) {
      this.userData.dataSource.technology = technology
      this.userData.dataSource.method = ''
      this.userData.dataSource.otherMethod = ''
    },

    resetResearchGoals() {
        this.userData.researchGoals = {}
        this.activeGoalsCounter = 0
    },

    reset() {
      this.userData = {
        contact: {
          name: '',
          institution: '',
          email: '',
        },
        researchGoals: {},
        dataSource: {
          technology: '',
          otherOmics: '',
          method: '',
          otherMethod: '',
          singleBulk: 'single cell technology',
          conditions: 3,
          samples: 10,
          cells: 100000,
          comment: '',
        },
      }

      this.activeGoalsCounter = 0
      this.formIsInProgress = false
    },

    onPopState(ev) {
      if (!ev.state || !ev.state.progressStep) {
        this.closeOverlay()
        return
      }

      let { progressStep } = ev.state

      // Close overlay if user has completed form
      if (progressStep === 4) {
        this.closeOverlay()
      }

      if (!this.overlayIsOpen) {
        this.overlayIsOpen = true
      }

      // Always enter first step
      if (progressStep === 1) {
        this.progressStep = progressStep
        return
      }

      // It is ok to enter an invalid step, but all previous steps have to be valid
      if (this.formIsValid((progressStep - 1))) {
        this.progressStep = progressStep
      } else {
        // If the conditions for a step are not met, we go back to the previous step
        this.progressStep = progressStep - 1
        window.history.replaceState({ progressStep: this.progressStep }, '')
      }
    },

    formIsValid(step) {
      let isValid = []

      if (step >= 4) {
        return true
      }

      for (let i = 1; i <= step; i++) {
        isValid.push(this.validateStep[i].bind(this)())
      }

      return isValid.filter((val) => !val).length === 0
    },

    validateStep: {
      1: function() {
        return this.activeGoalsCounter > 0
      },

      2: function() {
        if (this.userData.dataSource.technology && !(this.userData.dataSource.technology === 'epigenomics' || this.userData.dataSource.technology === 'proteomics' || this.userData.dataSource.technology === 'other')) {
          return true
        }

        if ((this.userData.dataSource.technology === 'epigenomics' || this.userData.dataSource.technology === 'proteomics') && this.userData.dataSource.method) {
          return true
        }

        if (this.userData.dataSource.technology === 'other' && this.userData.dataSource.otherOmics !== '') {
          return true
        }

        return false
      },

      3: function() {
        if (this.userData.contact.name !== '' && this.userData.contact.institution !== '' && this.userData.contact.email !== '') {
          // placeholder for actual validation of contact form
          return true
        }

        return false;
      }
    },
})
