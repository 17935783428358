import Alpine from 'alpinejs'
import AlpineIntersect from '@alpinejs/intersect'
import AlpineCollapse from '@alpinejs/collapse'
import AlpineFocus from '@alpinejs/focus'

import 'lazysizes'

import Swup from 'swup'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import SwupBackgroundScenePlugin from './swup/SwupBackgroundScenePlugin'

// Components
import PageHeader from './components/PageHeader.js'
import Body from './components/Body.js'
import Publications from './components/Publications.js'
import LeadGenerator from './components/LeadGenerator.js'
import Accordion from './components/Accordion.js'
import Collapsible from './components/Collapsible.js'

const swup = new Swup({
  containers: ['#swup-main', '#swup-page-header'],
  animationSelector: '[class*="swup-transition-"]',
  linkSelector:  `a[href^="${window.location.origin}"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])`,
  plugins: [
    new SwupHeadPlugin({
      persistTags: 'style[data-tp-style]',
    }),
    new SwupBodyClassPlugin(),
    new SwupScrollPlugin(),
    new SwupBackgroundScenePlugin(),
  ],
})

swup.on('contentReplaced', () => {
  if (window.HalftoneImageScene) {
    window.HalftoneImageScene.updateAndRender(document)
  }

  const page = swup.cache.getCurrentPage()

  if (page) {
    window.BackgroundScene.lookAt(page.model ?? 'helix')
    window.BackgroundScene.setScrollable(page.scrollable === true ? page.scrollable : false)
  }

  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
})

// Components
Alpine.data('Accordion', Accordion)
Alpine.data('Body', Body)
Alpine.data('Collapsible', Collapsible)
Alpine.data('LeadGenerator', LeadGenerator)
Alpine.data('PageHeader', PageHeader)
Alpine.data('Publications', Publications)

window.Alpine = Alpine
Alpine.plugin(AlpineCollapse)
Alpine.plugin(AlpineFocus)
Alpine.plugin(AlpineIntersect)
Alpine.start()
