export default () => ({
    search: '',
    category: 'all',
    results: 0,
    fakeSpin: false,
    resultsInViewport: false,
    searchInViewport: false,

    checkSearchTerm(field, search) {
      let includes = true
      const searchArray = search.split(" ")

      searchArray.forEach(word => {
        if(!(field.toLowerCase().includes(word.toLowerCase()) || !search)) {
          includes = false
        }
      })
      return includes
    },

    showPublication(title, authors, categories) {
      const includesTitle = this.checkSearchTerm(title, this.search)
      const includesAuthors = this.checkSearchTerm(authors, this.search)
      const matchesCategories = categories.includes(this.category) ? true : false

      const showItem = (includesTitle || includesAuthors) && matchesCategories
      return showItem
    },

    calculateResults(scrollTo = '') {
      if(scrollTo === 'serp') {
        this.$refs.serp.scrollIntoView({ behavior: "smooth" })
      }
      this.results = document.getElementsByClassName('serp-item').length
      this.fakeSpin = true
      setTimeout(() => { this.fakeSpin = false }, 300)
    },

    changeCategory(category, scrollTo = 'searchbar') {
      this.category = category
      if(scrollTo === 'serp') {
        this.$refs.serp.scrollIntoView({ behavior: "smooth" })
      } else if(scrollTo === 'searchbar') {
        this.$refs.publications.scrollIntoView({ behavior: "smooth" })
      }
    }
})
