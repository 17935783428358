import Plugin from '@swup/plugin'

export default class SwupBackgroundScenePlugin extends Plugin {

  mount() {
    this.swup.on('contentReplaced', (ev) => {
      const page = this.swup.cache.getCurrentPage()
      const { backgroundModel, backgroundScrollable } = this.getBackgroundSceneConfigFromHTML(page.originalContent)
      page.model = backgroundModel
      page.scrollable = backgroundScrollable === ''
    })
  }

  getBackgroundSceneConfigFromHTML(html) {
    const dom = document.createElement('html')
    dom.innerHTML = html
    const body = dom.getElementsByTagName('body')[0]

    const { backgroundModel, backgroundScrollable } = body.dataset

    dom.innerHTML = ''

    return {
      backgroundModel,
      backgroundScrollable,
    }
  }
}
